.active-sentence{
    padding: .25em 0;
    background: #86dffa;
}

.searched{
    padding: .25em 0;
    background: #e6f14c;
}

.hovered-timestamp{
    text-decoration: underline;
    color: royalblue;
}

.timestamp{
    color: gray;
}